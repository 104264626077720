import main from 'base/main.js'

import App from './components/App'
import router from './router'
import store from './store'

// const code = 'cheryomoda'
const code = 'cheryomoda'
const title = 'Программа мотивации корпоративных продаж Chery OMODA'
const pageTitle = 'Программа мотивации корпоративных продаж Chery OMODA'
const region = 'ru'

router.beforeEach((to, from, next) => {
  if (typeof to.meta !== 'undefined' && typeof to.meta.title !== 'undefined') {
    document.title = [pageTitle, to.meta.title].join(' - ')
  } else {
    document.title = pageTitle
  }

  next()
})

const app = main.getInstance({ App, router, store, code, region, title })

export default app
